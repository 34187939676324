import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion"
import React, { useEffect, useRef, useState } from 'react'
import grid from "../assets/grid.svg"
import moon from "../assets/moon.png"
import ship from "../assets/ship.png"
import "./Home.css"
import logo from "../assets/logo.svg"
import aboutbg from "../assets/about-bg.jpg"
function Navbar() {
    const [isFixed, setIsFixed] = useState(false);
    const [open, setOpen] = useState(false);

    const isOpen = () => {
        setOpen(!open);
    }

    const closeMenu = () => {
        setOpen(false);
    }
    const item = {
        exit: {
            opacity: 0,
            height: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.5,
                delay: 0.6
            }
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 500) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        });
    }, []);

    return (
        <>
            <motion.div
                initial={{ opacity: 0, translateY: -100, position: "absolute" }}
                animate={{
                    position: isFixed ? "fixed" : "absolute",
                    translateY: 0,
                }}
                exit={{
                    opacity: 0,
                    scaleY: -100,
                }}
                layout
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{
                    delay: !isFixed ? "0.2" : "0",
                    type: !isFixed ? "spring" : "",
                    duration: "0.5",
                }}
                className={`navbar h-20 md:h-24 w-full absolute top-0  flex flex-row items-center justify-between px-4 md:px-8 z-40 ${isFixed ? "bg-black/20 backdrop-blur-md " : ""
                    }`}
            >
                <a
                    href="/"
                    className={`text-white logo flex flex-row items-center font-venite text-xl justify-start gap-2 h-1/2${isFixed
                        ? "text-[#202020]"
                        : "text-white "
                        }`}
                >
                    <img src={logo} alt="" className="md:h-8 h-6" />
                </a>
                <div className="md:flex flex-row gap-12 items-center justify-center hidden">
                    <motion.a href="#Home"
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 1 }}
                        exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                                ease: "easeInOut",
                                delay: 0.7
                            }
                        }} className="text-white text-2xl font-extralight font-sans uppercase"
                    >Home</motion.a>
                    <motion.a href="#about"
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: .8 }}
                        exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                                ease: "easeInOut",
                                delay: .6
                            }
                        }} className="text-white text-2xl font-extralight font-sans uppercase"
                    >About galaxy</motion.a>
                    <motion.a href=""
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: .6 }}
                        exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                                ease: "easeInOut",
                                delay: .5
                            }
                        }} className="text-white text-2xl font-extralight font-sans uppercase"
                    >Portfolio</motion.a>
                    <motion.a href=""
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: .4 }}
                        exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                                ease: "easeInOut",
                                delay: .4
                            }
                        }} className="text-white text-2xl font-extralight font-sans uppercase"
                    >Partnership</motion.a>
                    <motion.a href=""
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: .2 }}
                        exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                                ease: "easeInOut",
                                delay: .2
                            }
                        }} className="text-white text-2xl font-extralight font-sans uppercase"
                    >Social</motion.a>
                </div>
                <div className="button 4 flex flex-row gap-4 items-center justify-center">
                    <motion.button
                        className={`font-semibold font-venite  text-sm md:text-xl  rounded-full px-3 py-1 md:px-6 md:py-3 text-center flex flex-row items-center justify-center gap-4 border-2 ${isFixed
                            ? " border-[#202020] text-white fill-white"
                            : "text-white border-white fill-white"
                            }`}
                        whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                        whileTap={{ scaleY: 0.9, scaleX: 0.9 }}
                    >
                        Try Now
                        <svg width="25" height="26" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4584 4.10418C16.4584 4.90959 15.8054 5.56251 15 5.56251C14.1946 5.56251 13.5417 4.90959 13.5417 4.10418V1.91668C13.5417 1.11126 14.1946 0.458344 15 0.458344C15.8054 0.458344 16.4584 1.11126 16.4584 1.91668V4.10418Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.125 15.0417H22.6854C21.88 15.0417 21.2271 15.6946 21.2271 16.5C21.2271 17.3054 21.88 17.9583 22.6854 17.9583H26.5792C25.9082 23.2425 21.7435 27.4013 16.4584 28.0646V15.0417C16.472 14.9547 16.472 14.8661 16.4584 14.7792C18.2768 14.1018 19.3494 12.2195 19.0052 10.3098C18.661 8.40002 16.9989 7.01064 15.0584 7.01064C13.1178 7.01064 11.4557 8.40002 11.1115 10.3098C10.7673 12.2195 11.8399 14.1018 13.6584 14.7792C13.6516 14.8665 13.6516 14.9543 13.6584 15.0417V28.0646C8.32786 27.4509 4.1033 23.2805 3.42085 17.9583H6.64377C7.44919 17.9583 8.1021 17.3054 8.1021 16.5C8.1021 15.6946 7.44919 15.0417 6.64377 15.0417H1.87502C1.0696 15.0417 0.416687 15.6946 0.416687 16.5C0.416687 24.5542 6.94587 31.0833 15 31.0833C23.0542 31.0833 29.5834 24.5542 29.5834 16.5C29.5834 15.6946 28.9304 15.0417 28.125 15.0417ZM15 12.125C14.396 12.125 13.9063 11.6353 13.9063 11.0313C13.9063 10.4272 14.396 9.93751 15 9.93751C15.6041 9.93751 16.0938 10.4272 16.0938 11.0313C16.0859 11.632 15.6008 12.1172 15 12.125Z" fill="white" />
                        </svg>

                    </motion.button>

                    <AnimatePresence>
                        {
                            !open ? (
                                <a className="menu md:w-6 md:h-6  flex items-start justify-between gap-2 flex-col md:hidden" onClick={isOpen}>
                                    <span className="md:h-1 h-0.5 md:w-6 w-6 bg-white"></span>
                                    <span className="md:h-1 h-0.5 md:w-4 w-3 bg-white"></span>
                                    <span className="md:h-1 h-0.5 md:w-6 w-6  bg-white"></span>
                                </a>
                            ) : (<a className="menu w-6  flex items-start justify-between flex-col md:hidden" onClick={isOpen}>
                                <span className="h-1 w-6 bg-white rotate-45 absolute"></span>
                                <span className="h-1 w-6 bg-white -rotate-45"></span>
                            </a>)}</AnimatePresence>

                </div>


            </motion.div >
            <AnimatePresence>
                {
                    open && (
                        <motion.div className="menu_container bg-black/10 fixed z-40 w-full backdrop-blur-xl h-screen flex flex-col items-center justify-center gap-8"
                            variants={item}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "100vh", opacity: 1 }}
                            transition={{ duration: .5 }}
                            exit="exit"
                        >

                            <motion.a href="#Home"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .5 }}
                                onClick={isOpen}
                                exit={{
                                    opacity: 0,
                                    y: 90,
                                    transition: {
                                        ease: "easeInOut",
                                        delay: 0.5
                                    }
                                }} className="text-white text-4xl font-extralight font-sans uppercase"
                            >Home</motion.a>
                            <motion.a href="#about"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .4 }}
                                onClick={isOpen}
                                exit={{
                                    opacity: 0,
                                    y: 90,
                                    transition: {
                                        ease: "easeInOut",
                                        delay: .4
                                    }
                                }} className="text-white text-4xl font-extralight font-sans uppercase"
                            >About galaxy</motion.a>
                            <motion.a href=""
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .3 }}
                                exit={{
                                    opacity: 0,
                                    y: 90,
                                    transition: {
                                        ease: "easeInOut",
                                        delay: .3
                                    }
                                }} className="text-white text-4xl font-extralight font-sans uppercase"
                            >Portfolio</motion.a>
                            <motion.a href=""
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .2 }}
                                exit={{
                                    opacity: 0,
                                    y: 90,
                                    transition: {
                                        ease: "easeInOut",
                                        delay: .2
                                    }
                                }} className="text-white text-4xl font-extralight font-sans uppercase"
                            >Partnership</motion.a>
                            <motion.a href=""
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .1 }}
                                exit={{
                                    opacity: 0,
                                    y: 90,
                                    transition: {
                                        ease: "easeInOut",
                                        delay: .1
                                    }
                                }} className="text-white text-4xl font-extralight font-sans uppercase"
                            >Social</motion.a>
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    );
}

function Home() {
    return (
        <div className="Main">
            <Navbar />
            <div className="Home flex items-center justify-center h-screen bg-black relative overflow-hidden" id="Home">
                <motion.img initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 0.2
                    }}
                    viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0' />
                <motion.div initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1, }}
                    transition={{
                        delay: 1,
                        duration: 1,
                    }}
                    viewport={{ margin: '-80% 0px 0px 0px' }} className="gradient h-1/4 md:h-1/3 w-full bg-gradient-to-t from-black to-transparent z-20 bottom-0 absolute "></motion.div>
                <motion.img
                    initial={{
                        opacity: 0, rotate: "45deg", translateY: 100
                    }}
                    whileInView={{ opacity: 1, rotate: "0deg", translateY: 0 }}
                    transition={{
                        duration: 1,
                    }}
                    viewport={{ margin: '-80% 0px 0px 0px' }}
                    src={moon}
                    className='w-full moon absolute md:top-[60%] top-[80%] z-10 object-cover' />
                <motion.img
                    initial={{
                        translateX: 200,
                        translateY: 100,
                    }}
                    whileInView={{ translateX: 0, translateY: 0 }}
                    transition={{
                        duration: 1,
                    }}

                    viewport={{ once: true }}
                    src={ship}
                    className='w-fit absolute top-0 right-0 z-10 my-20' />
                <div className="container flex flex-col items-start justify-center gap-2 z-20 md:w-1/2 px-4">
                    <motion.h1 className='font-venite text-white text-4xl md:text-6xl font-bold '>UNLOCK ETERNAL PROFITS - ETERNA TRADES</motion.h1>
                    <motion.a className='bg-white px-4 py-2 rounded-full text-black flex flex-row gap-2 items-center justify-center font-venite text-xl '>Check Out <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.6667 13L33.0202 12.6464L33.3738 13L33.0202 13.3536L32.6667 13ZM1.41667 13.5C1.14053 13.5 0.916674 13.2761 0.916674 13C0.916674 12.7239 1.14053 12.5 1.41667 12.5V13.5ZM20.5202 0.146447L33.0202 12.6464L32.3131 13.3536L19.8131 0.853553L20.5202 0.146447ZM33.0202 13.3536L20.5202 25.8536L19.8131 25.1464L32.3131 12.6464L33.0202 13.3536ZM32.6667 13.5H1.41667V12.5H32.6667V13.5Z" fill="#222222" />
                    </svg>
                    </motion.a>
                </div>

            </div>
            <div className="about flex items-center justify-center min-h-screen bg-black relative" id="about">
                <motion.img initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 0.2
                    }}
                    viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0' />
                <div className="container flex flex-col items-start justify-center h-full w-full gap-2 z-20 md:p-20 p-4 py-20">
                    <motion.div initial={{
                        opacity: 0,
                    }}
                        whileInView={{ opacity: 1 }}
                        transition={{

                            duration: 2
                        }} className="card flex flex-col items-start justify-start h-fit w-full bg-white/5 rounded-3xl backdrop-blur-xl bg-blend-exclusion drop-shadow-xl md:p-20 p-8 relative">
                        <motion.img initial={{
                            opacity: 0,
                        }}
                            whileInView={{ opacity: 1 }}
                            transition={{

                                duration: 2
                            }} src={aboutbg} alt="" className="absolute w-full h-full z-0 top-0 right-0 blur-md" />
                        <motion.h1 initial={{
                            opacity: 0,
                        }}
                            whileInView={{ opacity: 1 }}
                            transition={{
                                delay: 0.2,
                                duration: 0.5,
                            }} className='font-venite text-4xl md:text-6xl font-black z-10 text-white'>Introducing</motion.h1>
                        <motion.p initial={{
                            opacity: 0,
                            translateY: 100,
                        }}
                            whileInView={{ opacity: 1, translateY: 0 }}
                            transition={{
                                delay: 0.4,
                                duration: 0.5,
                            }} className='font-sans text-sm md:text-xl text-white z-10 mt-8 my-2 md:my-4'>Galaxy Ventures Capital, your premier destination for cutting-edge investment opportunities and global market expansion strategies. At Galaxy Ventures Capital, we specialize in identifying and nurturing valuable projects with the potential to make a significant impact on the international stage.</motion.p>
                        <motion.p initial={{
                            opacity: 0,
                            translateY: 100,
                        }}
                            whileInView={{ opacity: 1, translateY: 0 }}
                            transition={{
                                delay: 0.6,
                                duration: 0.5,
                            }} className='font-sans text-sm md:text-xl text-white z-10 my-2 md:my-4'>At Galaxy Ventures Capital, we understand that success in today's fast-paced global economy requires more than just capital—it demands vision, innovation, and a relentless commitment to excellence. That's why we're dedicated to providing our portfolio companies with the strategic guidance, resources, and support they need to thrive in any market, anywhere in the world.</motion.p>
                        <motion.p initial={{
                            opacity: 0,
                            translateY: 100,
                        }}
                            whileInView={{ opacity: 1, translateY: 0 }}
                            transition={{
                                delay: 0.8,
                                duration: 0.5,
                            }} className='font-sans text-sm md:text-xl text-white z-10 my-2 md:my-4'>Join us on our mission to discover, nurture, and elevate the next generation of game-changing ventures. Together, we'll redefine what's possible and unlock infinite opportunities for growth and prosperity. Welcome to Galaxy Ventures Capital—where innovation meets opportunity, and the sky's the limit.</motion.p>
                        <motion.a initial={{
                            opacity: 0,
                            translateX: 100
                        }}
                            whileInView={{ opacity: 1, translateX: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: 1
                            }} className='bg-white px-4 py-2 rounded-full text-black flex z-10 flex-row gap-2 items-center justify-center font-venite text-xl my-4'>White Paper <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32.6667 13L33.0202 12.6464L33.3738 13L33.0202 13.3536L32.6667 13ZM1.41667 13.5C1.14053 13.5 0.916674 13.2761 0.916674 13C0.916674 12.7239 1.14053 12.5 1.41667 12.5V13.5ZM20.5202 0.146447L33.0202 12.6464L32.3131 13.3536L19.8131 0.853553L20.5202 0.146447ZM33.0202 13.3536L20.5202 25.8536L19.8131 25.1464L32.3131 12.6464L33.0202 13.3536ZM32.6667 13.5H1.41667V12.5H32.6667V13.5Z" fill="#222222" />
                            </svg>
                        </motion.a>

                    </motion.div>
                </div>
            </div>
            <HorizontalScrollCarousel />
            <div className="portfolio flex items-center justify-center min-h-screen bg-black relative" id="portfolio">
                <motion.img initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 0.2
                    }}
                    viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0' />
                <div className="container flex flex-col items-center justify-center gap-2 z-20 md:w-1/2 px-4 py-16">
                    <motion.h1 initial={{
                        opacity: 0,
                    }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                        }} className='font-venite text-4xl md:text-6xl font-black text-center text-white my-10'>Portfolio.</motion.h1>
                    <div className="row flex flex-row items-center justify-center flex-wrap gap-16 m-8">
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://s1.coincarp.com/logo/1/mystikonetwork.png?style=200&v=1711896643" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://pbs.twimg.com/media/GHkszJbWYAEmwbD.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://guild.xyz/_next/image?url=https%3A%2F%2Fguild-xyz.mypinata.cloud%2Fipfs%2FQmZYeRaJgAWTffwsAAbUC263Y9PaN7CHShMqGFZnLKYN48&w=3840&q=70" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://3585282718-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F8SjVmiLnmZLuf9Wlo9im%2Ficon%2FDHTD4AF12MxB289mEUM2%2FLOGO.jpeg?alt=media&token=970be7d5-7ea1-4a12-b5bf-6c694add358f" alt="" className="object-contain bg-white p-2 item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://assets-global.website-files.com/6364e65656ab107e465325d2/63f7d607f55dd9daf23f5763_AZxay8H9X7xe8nDPsrk9E8z-uQLiJLuXttgQbgbWs6g.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://miro.medium.com/v2/resize:fit:2400/1*Rk8UralLpyENrb4RnMzZ6g.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://seeklogo.com/images/R/Rainmaker_Systems-logo-82790E7B27-seeklogo.com.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://assets.coingecko.com/coins/images/34560/large/DeChat_Logo_200_x_200.png?1705382843" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://icodrops.com/wp-content/uploads/2024/01/BOWP3iCW_400x400.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-black p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://appzforpc.com/wp-content/uploads/2023/06/download-carbon-browser-for-pc.webp" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://s3.coinmarketcap.com/static-gravity/image/6c8f5db9850546acb32c7dd74ab49b10.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-black p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://s1.coincarp.com/logo/1/playbux.png?style=200&v=1687489620" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://chainbroker.io/_next/image/?url=https%3A%2F%2Fstatic.chainbroker.io%2Fmediafiles%2Fprojects%2Fcatamoto%2Fcatamoto.jpeg&w=2560&q=75" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-black p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://d2pasa6bkzkrjd.cloudfront.net/_resize/consensus2023/partner/500/site/consensus2023/images/userfiles/partners/7120a24e97744d16b513cd6c7502f386.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://assets-global.website-files.com/64354b8ce4872ad8cd1c7b04/64cbab06a7b38caff30097f3_chaingpt-icon.svg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl object-contain bg-white p-2" />
                    </div>
                </div>

            </div>
            <div className="partners flex items-center justify-center min-h-screen bg-black relative" id="partners">
                <motion.img initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 0.2
                    }}
                    viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0' />
                <div className="container flex flex-col items-center justify-center gap-8 z-20 md:w-1/2 px-4 py-16">
                    <motion.h1 initial={{
                        opacity: 0,
                    }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                        }} className='font-venite text-4xl md:text-6xl font-black text-center text-white'>CEX Partners.</motion.h1>
                    <div className="row flex flex-row items-center justify-center flex-wrap gap-16 m-8">
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://assets.staticimg.com/cms/media/3gfl2DgVUqjJ8FnkC7QxhvPmXmPgpt42FrAqklVMr.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://altcoinsbox.com/wp-content/uploads/2022/10/bybit-logo-white.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://www.gate.io/images/gate.io_logo.png" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://yourcryptolibrary.com/wp-content/uploads/2023/02/MEXC-Logo.jpg" alt="" className="object-contain bg-white p-2 item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />

                    </div>
                    <motion.h1 initial={{
                        opacity: 0,
                    }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                        }} className='font-venite text-4xl md:text-6xl font-black text-center text-white'>MM Partners.</motion.h1>
                    <div className="row flex flex-row items-center justify-center flex-wrap gap-16 m-8">
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://pbs.twimg.com/profile_images/1671919545198559237/rIzcaw1F_400x400.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />
                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://cryptoslate.com/wp-content/uploads/2022/12/DWF-Labs-social.jpg" alt="" className="item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl bg-black object-contain" />

                        <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay:0.2,
                    duration: 0.5
                }}  src="https://miro.medium.com/v2/resize:fit:2400/1*vuDyQJYD3tRpW3aRNxsxuw.png" alt="" className="object-contain bg-white p-2 item md:h-40 md:w-40 h-20 w-20 md:rounded-3xl rounded-xl shadow-black shadow-xl" />

                    </div>
                </div>

            </div>
            <div className="footer flex items-center justify-center min-h-screen bg-black relative">
                <motion.img initial={{
                    opacity: 0,
                }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 0.2
                    }}
                    viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0' />
                <div className="container flex flex-col items-center justify-center gap-2 z-20 md:w-1/2 px-4">
                    <motion.h1 initial={{
                        opacity: 0,
                    }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                        }} className='font-venite text-4xl md:text-6xl font-black text-center text-white'>Connect with Galaxy.</motion.h1>
                    <div className="socials bg-white/15 backdrop-blur-md p-2 rounded-full my-8 flex flex-row gap-2 border-2 border-[#7E7E7E]">
                        <motion.a className="icon bg-white w-12 h-12 fill-[#202020] items-center justify-center flex rounded-full"
                            href="https://x.com/gxvofficial" target="_blank" whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                            whileTap={{ scaleY: 0.9, scaleX: 0.9 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                            </svg>
                        </motion.a>
                        <motion.a className="icon bg-white w-12 h-12 fill-[#202020] items-center justify-center flex rounded-full " href="https://t.me/GalaxyVenturesChat" target="_blank" whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                            whileTap={{ scaleY: 0.9, scaleX: 0.9 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                            </svg>
                        </motion.a>
                        <motion.a className="icon bg-white w-12 h-12 fill-[#202020] items-center justify-center flex rounded-full"
                            href="mailto:business@galaxyventures.co" whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                            whileTap={{ scaleY: 0.9, scaleX: 0.9 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                            </svg>
                        </motion.a>
                    </div>
                </div>

            </div>

        </div>
    )
}


const HorizontalScrollCarousel = () => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

    return (

        <section ref={targetRef} className="relative h-[300vh] features  bg-black ">
            <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{
                    delay: 0.2
                }}
                viewport={{ once: true }} src={grid} className='h-full w-full absolute object-cover z-0 ' />
            <div className="sticky top-20 flex flex-col h-screen items-start overflow-hidden gap-20 py-14">
                <motion.h1 initial={{
                    opacity: 0,
                    translateY: 100
                }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{
                        delay: 0.2,
                        duration: 0.5,
                    }} className='font-venite text-6xl md:text-6xl font-black text-white w-full text-center'>Serivces</motion.h1>
                <motion.div style={{ x }} className="flex md:gap-8 gap-4">
                    {cards.map((card) => {
                        return <Card card={card} key={card.id} />;
                    })}
                </motion.div>
            </div>
        </section>

    );
};


const Card = ({ card }) => {
    return (
        <motion.div
            initial={{
                opacity: 0,
            }}
            whileInView={{
                opacity: 1
            }}
            transition={{ delay: 0.2, duration: 0.5 }}
            key={card.id}
            className="group relative h-[350px] w-[350px] md:h-[450px] md:w-[450px]  overflow-hidden bg-black   rounded-3xl hover:shadow-2xl hover:shadow-blue-500/25 "
        >
            <motion.div initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{

                    duration: 0.2
                }} className="absolute bg-gradient-to-b from-black/50 via-black/50 to-transparent w-full h-full z-10 top-0 right-0 blur-sm object-cover" >
            </motion.div>
            <motion.img initial={{
                opacity: 0,
            }}
                whileInView={{ opacity: 1 }}
                transition={{

                    duration: 0.2
                }} src={card.background} alt="" className="absolute w-full h-full z-0 top-0 right-0  object-cover" />
            <div className="absolute inset-0 z-10 flex flex-col items-start justify-start ">
                <p className=" px-8 mt-12 mb-4 md:text-5xl text-3xl font-black uppercase text-white font-venite">
                    {card.title}
                </p>
                <p className="px-8 font-light md:text-2xl  uppercase text-white ">{card.description}</p>
            </div>
        </motion.div>
    );
};

const cards = [
    {
        id: 1,
        title: "Advisory",
        description: "Our team of seasoned experts is committed to crafting personalized strategies designed to align with your specific financial objectives.",
        background: "https://i.pinimg.com/originals/b7/b5/79/b7b5797661b306bb0c607496c8895e68.jpg"
    },
    {
        id: 2,
        title: "KOLS Management",
        description: "Our dedicated team of experts works tirelessly to orchestrate seamless collaborations that drive meaningful engagement and deliver measurable results.",
        background: "https://i.pinimg.com/564x/02/5d/70/025d706542030a1ce3450f9d3e0e3558.jpg"
    },
    {
        id: 3,
        title: "CEX Listings",
        description: "Our comprehensive service streamlines the process of getting your token listed on leading centralized exchanges (CEXs), ensuring maximum exposure and market access for your project.",
        background: "https://i.pinimg.com/564x/57/9e/a9/579ea98e158cf1e62d2dd3abc4c3224f.jpg"
    },
    {
        id: 4,
        title: "Market Making Support",
        description: "Our comprehensive service is tailored to meet the unique needs of your project, providing expert guidance and execution to enhance market stability and efficiency.",
        background: "https://i.pinimg.com/736x/7d/29/0a/7d290a74289bb9bd3acac63eace05840.jpg"
    },
    {
        id: 5,
        title: "Website Support",
        description: "Our dedicated service ensures that your website remains secure, improve design, and user-friendly, allowing you to focus on your core business activities while we handle the technical aspects.",
        background: "https://i.pinimg.com/736x/5c/8e/9d/5c8e9d71611d396ed3ceb5d14ad09150.jpg"
    },

];

export default Home