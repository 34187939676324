import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';

function App() {
  return (
    <div className="App  relative">
      {/* <h1 className="fixed z-50 top-0 h-screen w-screen text-center flex items-center justify-center backdrop-blur-3xl font-venite text-white text-4xl md:text-6xl font-bold">Under Development</h1> */}
      <Home />
    </div>
  );
}

export default App;
